import { Flex, HStack, StackDivider, Text, chakra } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { GeneralSection } from '@/layout';
import { NavLink } from '@/layout/header';
import { SectionSize } from '@/layout/section';
import { createLog, useGridProps, useStateL } from '@/utils';

const log = createLog(`BannerSection`);

export interface BannerSectionProps {
  curPageIdx: number;

  // OPTIONAL
  isActive: boolean;
}

const BannerSection = ({
  curPageIdx,

  // OPTIONAL
  isActive = true,
}: BannerSectionProps) => {
  const { isMobile } = useGridProps();
  const [_isVLOpen, toggleVL] = useStateL(false, `isVLOpen`);

  // isActive
  useEffect(() => {
    log.debug(`isActive: ${isActive}`);
  }, [isActive]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isActive) {
        toggleVL(true);
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [isActive, toggleVL]);

  const createBottomNavbar = () => {
    return (
      <chakra.header shadow="md">
        <Flex
          align="center"
          color="gray.400"
          justify="space-around"
          maxW="1100px"
          mx="auto"
          mb="4px"
          py="1.75rem"
          w="100%"
        >
          <HStack
            color="blackAlpha.800"
            display={{ base: `none`, md: `flex` }}
            divider={<StackDivider borderColor="gray.300" />}
            fontSize={{ base: `1rem`, md: `1.2rem` }}
            fontWeight="600"
            spacing="5rem"
          >
            <NavLink href="/business/viral" name="바이럴 마케팅" />
            {/* <NavLink href="/business/sns" name="SNS 운영대행" /> */}
            <NavLink href="/business/media" name="언론 송출" />
            <NavLink href="/business/searchad" name="검색 광고" />
            <NavLink href="/business/marketing" name="종합 마케팅" />
          </HStack>
        </Flex>
      </chakra.header>
    );
  };

  return (
    <GeneralSection
      name="banner"
      // OPTIONAL
      px="0"
      sectionSize={SectionSize.FIT}
    >
      <Flex py="0" flexDir="column" align="center" justify="flex-end">
        <Flex
          align="center"
          bg={`linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(/pages/business/banner_bg.webp)`}
          bgAttachment="fixed"
          bgPos={{ base: `55% 76px`, md: `0 0` }}
          bgRepeat="no-repeat"
          bgSize={{ base: `300%`, md: `105%` }}
          flexDir="column"
          fontSize={{ base: `1.15rem`, md: `1.5rem` }}
          fontWeight="600"
          h={{ base: `28vh`, md: `30vh` }}
          justify="center"
          textShadow="0 0 3px rgba(0, 0, 0, 0.45)"
          w="100%"
        >
          <Text as="p">더 나은 마케팅을 위해 고민합니다.</Text>
          <Text as="p">경험을 통해 새로운 가치를 만들어냅니다.</Text>
        </Flex>
      </Flex>
      {!isMobile && createBottomNavbar()}
    </GeneralSection>
  );
};

export default BannerSection;
