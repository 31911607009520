import { Box, BoxProps, Heading, HeadingProps, TextProps } from '@chakra-ui/layout';
import { CSSObject, Text } from '@chakra-ui/react';
import React from 'react';

import { MotionBox, MotionBoxProps } from '@/components/chakra-ui/framer-motion';

import type { ReactNode } from 'react';
import type { StringOrArrayNode, StringsNode } from '@/types';
export interface BasicContent {
  // OPTIONAL
  key: React.Key;
  title?: StringOrArrayNode;
  body?: StringOrArrayNode;
}

export type BasicContentOrArray = BasicContent | BasicContent[];

export interface CreateChakraTextsProps {
  // OPTIONAL
  mb?: TextProps[`mb`];
  textProps?: TextProps;
}

export const createChakraTextsFromArray = ({
  contents,

  // OPTIONAL
  mb,
  textProps,
}: CreateChakraTextsProps & { contents: StringsNode }): ReactNode[] =>
  contents.map((content, i) => (
    <Text
      key={`text-${i}-${content.substring(0, 7)}`}
      mb={i < contents.length - 1 && mb}
      {...textProps}
    >
      {content}
    </Text>
  ));

export const createChakraTextsFromArrayOrString = ({
  contents,

  // OPTIONAL
  mb,
  textProps,
}: CreateChakraTextsProps & { contents: StringOrArrayNode }): ReactNode | ReactNode[] =>
  Array.isArray(contents)
    ? createChakraTextsFromArray({ contents, mb, textProps })
    : contents;

interface CreateChakraBasicContentsNodeProps {
  content: BasicContent;

  // OPTIONAL
  innerWrapProps?: MotionBoxProps;
  titleProps?: HeadingProps;
  bodyProps?: TextProps;
}

const createChakraBasicContentsNode = ({
  content,

  // OPTIONAL
  innerWrapProps,
  titleProps,
  bodyProps,
}: CreateChakraBasicContentsNodeProps): ReactNode => (
  <MotionBox key={content.key} mb="0" {...innerWrapProps}>
    <Heading as="h3" mb="0.5rem" textStyle="content.title-md" {...titleProps}>
      {createChakraTextsFromArrayOrString({
        contents: content.title,
        mb: `0.3rem`,
        // textProps: { textStyle: `content.title-md` },
      })}
    </Heading>
    <Text textStyle="content.body-md">
      {createChakraTextsFromArrayOrString({
        contents: content.body,
        mb: `0.3rem`,
        // textProps: { textStyle: `content.body-md` },
        textProps: bodyProps,
      })}
    </Text>
  </MotionBox>
);

export const createChakraBasicContents = ({
  contents,

  // OPTIONAL
  chakraStyles,
  wrapProps,
  innerWrapProps,
  titleProps,
  bodyProps,
}: {
  contents: BasicContentOrArray;

  // OPTIONAL
  chakraStyles?: CSSObject;
  wrapProps?: BoxProps;
  innerWrapProps?: MotionBoxProps;
  titleProps?: HeadingProps;
  bodyProps?: TextProps;
}): ReactNode => {
  const childrenProps = { innerWrapProps, titleProps, bodyProps };
  return (
    <Box __css={chakraStyles} {...wrapProps}>
      {Array.isArray(contents)
        ? contents.map((content) =>
            createChakraBasicContentsNode({ content, ...childrenProps }),
          )
        : createChakraBasicContentsNode({ content: contents, ...childrenProps })}
    </Box>
  );
};
