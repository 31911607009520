import {
  Box,
  CenterProps,
  Flex,
  Image,
  ImageProps,
  SimpleGrid,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { motionFadeUpProps } from '_/configs/motion-presets';

import { createChakraBasicContents } from '@/utils/react';

import type { BasicContentOrArray } from '@/utils/react';
import type { CardVariant } from '_/theme/components';
import type {
  BoxProps,
  HeadingProps,
  SimpleGridProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
export type { CardVariant };

export type CardSimpleGridProps = {
  columns?: SimpleGridProps[`columns`];
  maxW?: SimpleGridProps[`maxW`];
};

export const defaultCardSimpleGridProps: CardSimpleGridProps = {
  columns: { base: 1, md: 2 },
  maxW: { base: `500px`, md: `7xl` },
};

export interface CardProps {
  // OPTIONAL
  gridProps?: CardSimpleGridProps;

  align?: StackProps[`align`];
  bodyProps?: TextProps;
  cardWrapProps?: CenterProps;
  contentWrapProps?: BoxProps;
  contents: BasicContentOrArray;
  imgProps?: ImageProps;
  imgSrc?: string;
  imgWrapProps?: BoxProps;
  reverse?: boolean;
  titleProps?: HeadingProps;
  variant?: CardVariant;
}

const Card = ({
  // OPTIONAL
  gridProps = defaultCardSimpleGridProps,

  align = `flex-start`,
  bodyProps,
  cardWrapProps = {},
  contentWrapProps,
  contents,
  imgProps,
  imgSrc,
  imgWrapProps,
  reverse = false,
  titleProps,
  variant,
}: CardProps) => {
  const styles = useMultiStyleConfig(`Card`, { variant });

  return (
    <Flex justify="center" align="center" {...cardWrapProps}>
      <SimpleGrid
        dir={reverse && reverse === true ? `rtl` : `ltr`}
        columns={gridProps.columns}
        maxW={gridProps.maxW}
        mx="auto"
      >
        {imgSrc && (
          <Box __css={styles.header} {...imgWrapProps}>
            <Image
              __css={styles.header}
              src={imgSrc}
              alt="Card Header Image"
              w="full"
              fit="cover"
              {...imgProps}
            />
          </Box>
        )}
        {createChakraBasicContents({
          contents,
          // OPTIONAL
          chakraStyles: styles.body,
          wrapProps: {
            alignItems: align,
            dir: `ltr`,
            // __css: styles.body,
            ...contentWrapProps,
          },
          innerWrapProps: { ...motionFadeUpProps },
          titleProps,
          bodyProps,
        })}
      </SimpleGrid>
    </Flex>
  );
};

export default Card;
