import { Grid } from '@chakra-ui/react';
import React from 'react';

import { GeneralSection, SectionType } from '@/layout';

import type { ReactNode } from 'react';

export interface ProcessSectionLayoutProps {
  children: ReactNode;
}

const ProcessSectionLayout = ({ children }: ProcessSectionLayoutProps): JSX.Element => {
  return (
    <GeneralSection
      name="process"
      bgImg="/pages/business/viral/sections/process/bg.png"
      bgFixed
      description={
        <>
          왜 이노앤엠이어야 하는지{` `}
          <br className="md" />
          차별화된 서비스를 보여드리겠습니다
        </>
      }
      sectionType={SectionType.DARK}
      title="PROCESS"
    >
      <Grid
        templateColumns={{ base: `repeat(2, 1fr)`, md: `repeat(4, 1fr)` }}
        gap={{ base: `2rem`, md: `2.5rem` }}
        pt="2rem"
        px={{ md: `3rem` }}
      >
        {children}
      </Grid>
    </GeneralSection>
  );
};

export default ProcessSectionLayout;
