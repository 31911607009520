import { Box, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';

import { GeneralSection, ResponsiveBr, SectionSize } from '@/layout';

import type { GeneralSectionProps } from '@/layout';

export interface ContactSectionProps {
  // OPTIONAL
  handleInView?: (inView: boolean, entry: IntersectionObserverEntry) => void;
  sectionProps?: GeneralSectionProps;
}

const ContactSection = ({
  // OPTIONAL
  handleInView,
  sectionProps,
}: ContactSectionProps) => (
  <GeneralSection
    name="contact"
    // OPTIONAL
    bgImg="/pages/business/bg_contact.png"
    handleInView={handleInView}
    sectionSize={SectionSize.XL}
    {...sectionProps}
  >
    <Flex flexDir="column" align="center" justify="center">
      <Text
        color="white"
        fontSize={{ base: `1.2rem`, md: `1.6rem` }}
        fontWeight="600"
        mb="2.5rem"
        textAlign="center"
        textShadow="0 0 1.5px #fff"
      >
        함께 성장하는 기쁨, <ResponsiveBr breakpoint="md" />
        이노앤엠과 함께하고 싶으신가요?
      </Text>
      <Box
        border="3px solid blue"
        borderColor="brand.400"
        borderRadius="2.5rem"
        color="brand.400"
        fontSize={{ base: `1.2rem`, md: `1.6rem` }}
        fontWeight="600"
        p="0.75rem 2.75rem"
        _hover={{
          bgColor: `brand.400`,
          color: `white`,
        }}
      >
        <Link href="/contact" _hover={{ textDecor: `none` }}>
          CONTACT 바로가기
        </Link>
      </Box>
    </Flex>
  </GeneralSection>
);

export default ContactSection;
