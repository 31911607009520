import { Box, HTMLChakraProps, chakra } from '@chakra-ui/react';
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import _ from 'lodash';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { useLockBodyScroll } from 'react-use';

import { __DEV__ } from '_/configs';

import { NextPageButton } from '@/components';
import { Header, SEO } from '@/layout';
import { useStateL } from '@/utils';

import type { HeaderProps, SEOProps } from '@/layout';
import type { PropsWithChildren } from 'react';

export type PageLayoutProps = PropsWithChildren<{
  nextPageHref: string | null;

  // OPTIONAL
  headerProps?: HeaderProps;
  lockScroll?: boolean;
  mainProps?: HTMLChakraProps<'main'>;
  marginTopForHeader?: boolean;
  seoProps?: SEOProps;
  // showFloatingButtons?: boolean;
  showNextPageButton?: boolean;
}>;

const PageLayout = ({
  children,
  nextPageHref,

  // OPTIONAL
  headerProps,
  lockScroll = false,
  mainProps = {},
  marginTopForHeader = false,
  seoProps,
  showNextPageButton = false,
}: // showFloatingButtons = false,
PageLayoutProps) => {
  const mt = marginTopForHeader && { base: `76px`, md: `76px` };
  if (!_.has(mainProps, `mt`)) mainProps.mt = mt;

  useLockBodyScroll(lockScroll);

  const [isComplete, setIsComplete] = useStateL(false, `isComplete`);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  useEffect(() => {
    console.log(`scrollYProgress: ${JSON.stringify(scrollYProgress, null, 2)}`);
  }, [scrollYProgress]);

  useEffect(() => yRange.onChange((v) => setIsComplete(v >= 1)), [setIsComplete, yRange]);

  return (
    <>
      <SEO {...seoProps} />
      <Header {...headerProps}></Header>
      <chakra.main overflow="hidden" {...mainProps}>
        {children}
      </chakra.main>
      <NextPageButton /* show={showNextPageButton} */ show={false} href={nextPageHref} />
      {/* <SnsFloatingButtons show={showFloatingButtons} /> */}
      {__DEV__ && (
        <Box position="fixed" top="100px" left="20px">
          <svg
            className="progress-icon"
            style={{ width: `120px`, height: `120px` }}
            viewBox="0 0 60 60"
          >
            <motion.path
              fill="none"
              strokeWidth="5"
              stroke="#ccc"
              strokeDasharray="0px 10000px"
              d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
              style={{
                pathLength,
                rotate: 90,
                translateX: 5,
                translateY: 5,
                scaleX: -1, // Reverse direction of line animation
              }}
            />
            <motion.path
              fill="none"
              strokeWidth="5"
              stroke="#eee"
              d="M14,26 L 22,33 L 35,16"
              initial={false}
              strokeDasharray="0px 10000px"
              animate={{ pathLength: isComplete ? 1 : 0 }}
            />
          </svg>
        </Box>
      )}

      {/* <!-- Google Tag Manager --> */}
      <Script id="google-analytics-global-load">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MCWHHBXZ');
        `}
      </Script>
      {/* <!-- End Google Tag Manager --> */}

      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MCWHHBXZ"
          height="0"
          width="0"
          // style="display:none;visibility:hidden"
          style={{ display: `none`, visibility: `hidden` }}
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
};

export default PageLayout;
