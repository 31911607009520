import React from 'react';

import MotionMain from '@/components/chakra-ui/framer-motion/motion-main';

import type { PropsWithChildren } from 'react';
import type { HTMLChakraProps } from '@chakra-ui/react';

export const motionVariants = {
  hidden: { opacity: 0, x: 0, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export type PageTransitionProps = PropsWithChildren<{
  key?: React.Key;

  // OPTIONAL
  mainProps?: HTMLChakraProps<`main`>;
}>;

const PageTransition = ({
  key,
  children,

  // OPTIONAL
  mainProps,
}: PageTransitionProps): JSX.Element => {
  return (
    <MotionMain
      key={key}
      overflow="hidden"
      {...mainProps}
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={motionVariants}
      transition={{ type: `linear`, duration: 0.2 }}
    >
      {children}
    </MotionMain>
  );
};

export default PageTransition;
