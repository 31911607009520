import React, { useCallback } from 'react';

import { Footer } from '@/layout';
import { PageLayout } from '@/layouts';
import { t, useGridProps, useLog, useStateL } from '@/utils';
import { ContactSection } from '@/views/common/sections';

import BannerSection from './BannerSection';

import type { PropsWithChildren } from 'react';

const UNIT_NAME = `BusinessPageLayout`;

export type BusinessPageLayoutProps = PropsWithChildren<{
  curPageIdx: number;
  nextPageHref: string | null;
}>;

const BusinessPageLayout = ({
  children,
  curPageIdx,
  nextPageHref,
}: BusinessPageLayoutProps) => {
  const log = useLog(UNIT_NAME);

  const [showNextPageButton, setShowNextPageButton] = useStateL(
    false,
    `showNextPageButton`,
  );
  const handleContactSectionInView = useCallback(
    (inView: boolean, entry: IntersectionObserverEntry) => {
      log.debug(`inView: ${inView}, inViewEntry: ${JSON.stringify(entry)}`);
      setShowNextPageButton(inView);
    },
    [log, setShowNextPageButton],
  );

  return (
    <PageLayout
      marginTopForHeader
      nextPageHref={nextPageHref}
      seoProps={{
        title: t(`pages.about.seo.title`),
        description: t(`pages.about.seo.description`),
      }}
      // TODO: pageTransition
      // showFloatingButtons
      showNextPageButton={showNextPageButton}
    >
      <BannerSection
        curPageIdx={curPageIdx}
        // OPTIONAL
        isActive
      />
      {children}
      <ContactSection handleInView={handleContactSectionInView} />
      <Footer />
    </PageLayout>
  );
};

export default BusinessPageLayout;
