import { Stack, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { Card } from '@/components/chakra-ui';
import { GeneralSection, ResponsiveBr, SectionSize } from '@/layout';
import { t } from '@/utils';

import type { CardProps, CardVariant } from '@/components/chakra-ui';
export interface AboutSectionLayoutProps {
  name: string;
  title: string;
  card1Props: CardProps;

  // OPTIONAL
  card2Props?: CardProps;
  description?: string | ReactNode;
}

const AboutSectionLayout = ({
  name,
  title,
  card1Props,

  // OPTIONAL
  card2Props,
  description = (
    <>
      {t(`pages.business.common.sections.about.description1`)}
      <ResponsiveBr breakpoint="md" />
      {t(`pages.business.common.sections.about.description2`)}
    </>
  ),
}: AboutSectionLayoutProps): JSX.Element => {
  const variant = useBreakpointValue<CardVariant>({ base: `rounded`, md: `flat` });

  return (
    <GeneralSection
      name={name}
      disableAnimation
      description={description}
      pbMult={1.5}
      sectionSize={SectionSize.SM}
      title={title}
      titleProps={{ fontSize: { base: `1.8rem`, md: `2.7rem` } }}
      titleWrapProps={{ mb: { base: `2.75rem`, md: `5rem` }, mt: { md: `0.75rem` } }}
    >
      {variant && (
        <Stack spacing={{ base: `4rem`, md: 0 }}>
          <Card {...card1Props} variant={variant} />
          {card2Props ? (
            <Card {...card2Props} variant={variant} reverse={variant === `flat`} />
          ) : null}
        </Stack>
      )}
    </GeneralSection>
  );
};

export default AboutSectionLayout;
