import { Flex, Text, chakra } from '@chakra-ui/react';
import React from 'react';

import { GeneralSection, SectionType } from '@/layout';

import type { ReactNode } from 'react';

export interface SpecialtySectionLayoutProps {
  children: ReactNode;
}

const SpecialtySectionLayout = ({
  children,
}: SpecialtySectionLayoutProps): JSX.Element => (
  <GeneralSection
    name="specialty"
    sectionType={SectionType.LIGHT}
    title={
      <chakra.h2 textStyle="heading" mb="5" fontSize={{ base: `2.2rem`, md: `2.7rem` }}>
        이노앤엠만의{` `}
        <Text as="span" color="brand.300">
          특별함
        </Text>
      </chakra.h2>
    }
    titleTextUnderlineDividerProps={{ show: false }}
    titleWrapProps={{ mb: { base: `2rem`, md: `3rem` } }}
  >
    <Flex flexDir={{ base: `column`, md: `row` }} gap={{ base: `5rem`, md: `0` }}>
      {children}
    </Flex>
  </GeneralSection>
);

export default SpecialtySectionLayout;
